<template>
  <div>
    <topInfo></topInfo>
    <liveLogin></liveLogin>
    <copyright></copyright>
  </div>
</template>

<script>
import topInfo from "@components/top/top-info.vue";
import liveLogin from "./live-login/live-login.vue";
import copyright from "@components/copyright/copyright.vue";

export default {
  name: "login",
  components: {
    topInfo,
    liveLogin,
    copyright,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
</style>